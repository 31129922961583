import React from "react"
import { Helmet } from "react-helmet"
import { FiInstagram } from "react-icons/fi"
import styled from "styled-components"
import Layout, { Headline } from "../../components/layout"
import bg from "../../assets/img/3005.jpg"

const Message = styled.p`
  font-size: 18px;
`

export default function Confirmation() {
  return (
    <Layout bg={bg} hero={false}>
      <Helmet>
        <title>Get A Cash Offer Confirmation</title>
      </Helmet>
      <div className="container">
        <Headline>
          <h1>Confirmation</h1>
        </Headline>
        <Message>
          Thank you for submitting your information. We'll be in touch shortly!
          Feel free to contact us if you have any questions in the meantime.
          Email:{" "}
          <a href="mailto:contact@DirectBuyers.com">contact@DirectBuyers.com</a>{" "}
          <a href="tel:18442427355">1-844-242-SELL</a>{" "}
          <a
            href={`https://www.instagram.com/thedirectbuyers/`}
            title="Instagram"
          >
            @thedirectbuyers <FiInstagram />
          </a>
        </Message>
      </div>
    </Layout>
  )
}
